import React from "react";
import "../css/App.css";
import "../css/Stickers.css";


const Stickers = ({sticker1,sticker2,sticker3,sticker4,sticker5}) => (
<>
  <div className="sticker-container">
    
    <div className='sticker1'>
        <img className='sticker'src={sticker1}></img>
      </div>
    <div className='sticker2' >
        <img className='sticker'src={sticker2}></img>
      </div>
    <div className='sticker3'>
        <img className='sticker'src={sticker3}></img>
      </div>
    <div className='sticker4'>
        <img className='sticker'src={sticker4}></img>
      </div>
    <div className='sticker5'>
        <img className='sticker'src={sticker5}></img>
    </div>
</div>
       
        </>
)

export default Stickers;