import React from "react";
import '../css/App.css'

const GuideIntro = ({title, introduction, prompt}) => (
    <>
    <div className="title-container">
        <div className="intro-container">
<h2 className="guide-title">{title}</h2>
<div className="flex">
    <p className="guide-intro">{introduction}</p>
</div>
    </div>
    </div>




</>
)

export default GuideIntro;