import React from "react";
import "../css/App.css";
import GuideIntro from "../Components/GuideIntro";
import Checkbox from "../Components/Checkbox";
import Footer from "../Components/Footer";
import Card from "../Components/Card";
import Title from "../Components/Title";
import Checkboxb from "../Components/Checkboxb";
import PageEnd from "../Components/PageEnd.jsx";
import Stickers from '../Components/Stickers';

const sticker1 = [
  require('../images/Boosje.png')]
 const sticker2 = [
         require('../images/DetourGuideGuide.png')]
 const sticker3 = [
         require('../images/Dino.png') ]
 const sticker4 = [
         require('../images/Duif.png')]
 const sticker5 = [
         require('../images/Maartje.png')]


const Finding = () => (
<>

<div className="index background-blue">
<div className="blob-wrapper">
<div className="blob-container">
<div className="blob magicpattern-9"></div>
<div className="blob-2 magicpattern-8"></div>
</div>
</div>
    <div className="body">
        <GuideIntro title="#3 Finding Detour Guides">
        </GuideIntro>
      <div className="card-container card-wider card-left">
                            <p className="home-subt card-wide">3.1 Diversity Matters</p>
                            <p className="card-para card-middle background-db2">A creative from any discipline could potentially be a Detour Guide. Our Guides don't have to be young or "traditional" artists; no formal education in the arts is required. Nieuwe Instituut only works with makers, since it is a museum for design, architecture and digital culture. However, your organization might benefit from finding Detours of different disciplines, as long as they have their own expertise and know how to keep participants captivated. We advise to pick a diverse group – in expertise, ability, age, cultural background... But how can you find them?</p>
                            </div>
                        <div className="card-container card-wider card-middle">
                            <p className="home-subt card-wide">3.2 Put the Word Out</p>
                            <p className="card-para card-middle background-db2">An open call – a slightly untraditional one – is in our experience the best method to find Detour Guides. No formal cover letter needed, we devised a questionnaire for applicants, with questions such as “If you wouldn’t be a human, what would you be?” and “What are your wildest dreams for (name of your institution)?”. Their responses are less important than their attitude; you are looking for the people who are excited to play with you. </p>
                        </div>
                        <Stickers 
                 
                        sticker3={sticker5} 
                        sticker5={sticker3}   />
        <div className="card-container card-wider card-left">
            <p className="home-subt card-wide">3.3 Keep an open mind</p>
                        <p className="card-para card-middle background-db2">We strongly recommend allowing everybody to apply, independently of their educational background or artistic trajectory. Some people might look great on paper but falter when in front of a group; others might have had more unconventional but even richer journeys. We also do not require experience as a tour guide. Sometimes its absence can be a plus, as no ‘unlearning’ of previous methods is needed. Is this person a natural connector? Can you see their potential? If so, take a chance on them!</p>
                    </div>
              <div className="card-container card-wider card-left">
                    <p className="home-subt">3.4 See them in action</p>
                    <p className="card-para card-middle background-db2">To get to know your potential Detour Guides, we advise you to organize a group meeting with many of the candidates (even some you might not be sure about on paper). Then, ask them to prepare a 5-minute Detour about an exhibition or piece in your institution. Just so you can really experience their Detour potential in front of a group!</p>
              </div>
                      </div>
                      
    <div className="body">
    <Stickers 
                        sticker1={sticker2}
                        // sticker2={sticker1}
                        // sticker3={sticker3}
                        sticker4={sticker4}    />
    <p className="card-quote card-wide align-c">‘This Detour could be a therapeutic experience.’ (a Middle School Teacher who joined a Detour by Giovanni)</p>
        <div className="card-container">
        <p className="card-wider align-left home-subt-2 card-middle">Do you have a couple of promising people? Think you could be in the presence of a true Detour Guide? These are some signs to check for:</p> 
        <div className="cb-container align-left">
     
                <div  className="cb-div">
                    {/* <input type="checkbox" className="checkbox"></input> */}
                    <p className="check-info cb-text">They are open and curious; they have great energy and are captivating to interact with. They are also good at improvising and thinking quick.</p>       
                </div>
                <div  className="cb-div">
                    {/* <input type="checkbox" className="checkbox"></input> */}
                    <p className="check-info cb-text">They not only ask questions to the participants; they are also able to listen and follow through on their responses, starting a conversation.</p>       
                </div>
         
                <div  className="cb-div">
                    {/* <input type="checkbox" className="checkbox"></input> */}
                    <p className="check-info cb-text">They bring their artistry and unique experience to the table and are not afraid of sharing it.</p>       
                </div>
                <div  className="cb-div">
                    {/* <input type="checkbox" className="checkbox"></input> */}
                    <p className="check-info cb-text">They are very much themselves and invite others to do the same.</p>       
                </div>
            </div> 
           
        </div>

            </div>
            <Footer message ="Congrats! You have found yourself a Detour Guide."
            next="../pages/Working"
            back="../pages/Becoming"/>
<PageEnd/>
    </div>
    </>
)
export default Finding;