import React from 'react';
import Home from './pages/Home.jsx';
import Becoming from './pages/Becoming.jsx';
import Finding from './pages/Finding.jsx';
import Building from './pages/Building.jsx';
import Working from './pages/Working.jsx';
import Intro from './pages/Intro.jsx';
import Navigation from './Components/Navigation.jsx';
import Learning from './pages/Learning.jsx';
import Finish from './pages/Finish.jsx';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Heading from './Components/Header.jsx';
import ScrollToTop from './Components/ScrollToTop.js';
import Appendix from './pages/Appendix.jsx';
import './css/App.css';
import NavMobile from './Components/NavMobile.jsx';
// import {useNavigate} from react-router-dom;
<meta name="viewport" content="width=1000"></meta>

const App = () => (
<>
<Heading/>
<BrowserRouter basename='/'>
<Navigation/>
<NavMobile/>
{/* <NavigationTop/> */}
<Routes>
<Route path="/" element={<Home/>}></Route>
<Route path="pages/Intro" element ={<Intro/>}></Route>
<Route path="pages/Becoming" element={<Becoming/>}></Route>
<Route path="pages/Working" element={<Working/>}></Route>
<Route path="pages/Finding" element={<Finding/>}></Route>
<Route path="pages/Building" element={<Building/>}></Route>
<Route path="pages/Learning" element={<Learning/>}></Route>
<Route path="pages/Finish" element={<Finish/>}></Route>
<Route path="pages/Appendix" element={<Appendix/>}></Route>

</Routes>
<ScrollToTop/>
</BrowserRouter>

  </>
);

export default App;
