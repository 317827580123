import React from 'react';
import MuxPlayer from '@mux/mux-player-react';

const CustomMuxPlayer = ({
  playbackId,
  videoId,
  title,
}) => {
  return (
    <div className='mux-contain'>
    <MuxPlayer
      playbackId={playbackId}
      streamType="on-demand"
    />
    </div>
  );
};

export default CustomMuxPlayer;
