import React from "react";
import "../css/App.css";
import '../css/Header.css';
import {useNavigate} from 'react-router-dom';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
const Heading = () => (
<>
<div className="header-container">
    <div className="header-nav">
        
        <a href ="/" className="heading">Detour Guide Guide</a>
    </div>
    <div>
    </div>
    <div className="header-nav">
    <a href="/" className="header-link">Home</a>
    <a href="mailto:educatie@nieuweinstituut.nl" className="header-link">Contact</a>
    </div>
    </div>
</>
)

export default Heading;