import React from "react";
import "../css/App.css";
import {useNavigate} from 'react-router-dom';


const PageEnd = () => (
<>

<div className="page-end">
    <div className="container-1">
        {/* <div className="box-2"> 
                <div className="footer-title">
                    <p className="footer-line"></p>
                </div>
                <div className="footer-title">
                    <p className="footer-line"></p>
                </div>
            </div> */}
        <div className="box-3">
            <p className="footer-line">Click below to be kept up to date:</p> 
            <a href="https://forms.nieuweinstituut.nl/detour-guide-guide/" className="credit-link2">
            <div className="action">Join our Mailing List</div>
            </a>         
            <div className="footer-line-link">
                <a href="mailto:educatie@nieuweinstituut.nl" className="footer-line">educatie@nieuweinstituut.nl</a>
            </div>
        </div>
    </div>
    {/* <div className="container-1">
        <div className="box-3">
            <p className="footer-line"></p>          
        </div>
        <div className="box-4">
            <div className="social-contain-2">
                <div className="social" ahref="">Instagram</div>
                <div className="social" ahref="">Linkedin</div>
                <div className="social" ahref="">Twitter</div>  
            </div>
        </div>
        </div>     */}
    <div className="end-container"> 
        <div className="end-div">
            <div className="dev">
                <ul className="credit-list">
                <li><p className="credit">Team<a href="https://nieuweinstituut.nl/en/projects/plan-uw-bezoek/rondleidingen" className="credit-link">Co-Learning,</a>Nieuwe Instituut</p></li>
                <li><p className="credit">Text & Research:
                {/* <a href="http://sidepattern.com" className="credit-link"> */}
                    ímpetu studio
                    {/* </a> */}
                    </p></li>
                <li><p className="credit">Concept & Illustrations: Pia Canales</p></li>
                <li><p className="credit">Design & Development via<a href="http://sidepattern.com" className="credit-link">Side Pattern</a></p></li>
                </ul>
            </div>
   <ul className="credit-list">
    <li><p className="credit">© 2024 Detour Guide Guide. All Rights Reserved</p></li>
    </ul>
    </div>
    </div>
    </div>


</>
)

export default PageEnd;