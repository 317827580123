import React from "react";
import "../css/App.css";
import GuideIntro from "../Components/GuideIntro";
import Footer from "../Components/Footer";
import Card from "../Components/Card";
import Title from "../Components/Title";
import PageEnd from "../Components/PageEnd";
import LetsGetStarted from "../Components/LetsStartButton";
import Stickers from "../Components/Stickers.jsx";
const sticker1 = [
    require('../images/Boosje.png')]
   const sticker2 = [
           require('../images/DetourGuideGuide.png')]
   const sticker3 = [
           require('../images/Dino.png') ]
   const sticker4 = [
           require('../images/Duif.png')]
   const sticker5 = [
           require('../images/Maartje.png')]

const Finish = () => (
<>
<div className="blob-wrapper">
<div className="blob-container">
<div className="blob magicpattern-9"></div>
<div className="blob-2 magicpattern-8"></div>
</div>
</div>
    <div className="index background-blue">
                    <div className="body">
                    <GuideIntro title="#7 The Finish Line"
                    introduction="Now you are ready to start mobilizing your institution to become more Detour Guide-friendly! We tried to make this guide as comprehensive as possible while still concise; if any of your questions have been left unanswered, we would love to hear from you!">
                    </GuideIntro>
            <div className="section-box">
                <div className="card-container no-border grid">
                </div>
            </div> 

<Stickers sticker1={sticker1}
    sticker2={sticker2}
    sticker5={sticker5}/>
            <div className="section-box">
                <div className="card-container background-blue2">
                    <p className="align-left home-subt">Museum education & guided tours – an overview</p>
                    <p className="paragraph white-text">Everybody learns differently. From Gardner's Multiple Intelligences Theory to Piaget's constructivism, learning is seen as a process that is unique to each individual. People receive, interpret and integrate information in different ways, relating it to their lived experience and negotiating through dialogue with others.</p>
                    <p className="paragraph white-text">Museums, as cultural institutions, have long established themselves as places of learning (Falk & Dierking, 2018). In recent years, many museums are transcending their educational role, aiming to become sites of dialogue and exploration in relation to societal transitions (Bergevin, 2019). More and more institutions are realizing that participation is the way to not only broaden but deeply engage their audiences (Simon, 2010). However, museums' ways of teaching have not always accounted for their visitors' needs. As more and more voices join the conversation, how can they allow for more contemporary, participatory and diverse ways of learning?</p>
                    <p className="paragraph white-text">One of the most well-established formats through which museum education takes place is guided tours. Traditionally, they are created following a top-down approach, as museums decide what they would like their audiences to learn. Although understudied, the figure of the tour guide has long acted as a bridge between a museum and its audience (Schep, 2019). Museum guides are companions, co-creators and facilitators of visitors’ learning processes (Burnham and Kai-Kee, 2011; Schep, 2019). Their role requires a wide range of competencies, which include (but are not limited to) creating a safe environment, being enthusiastic and energetic, asking the right questions, and finding a balance between interaction and explaining (Schep, 2019).</p>
                    <p className="paragraph white-text">Best (2012) found traditional museum tours to be interactive "in subtle and ongoing ways". She also remarked on the importance of museums to make their audience feel engaged, and how that engagement might look different for new generations, who have grown up with more interactive educational systems. In recent years, there has been an increased presence of activity-based teaching in the art museum (which is to say, focused on hands-on and embodied learning) (Kai-Kee et al., 2020). Not only have these types of approaches had great success in reaching new audiences; they create memorable learning experiences by channelling the power of play.</p>
                    <p className="paragraph white-text">To recap, museums want to be a place of learning, dialogue and engagement. To achieve this, they should adapt to the needs of their audiences. As they grow more diverse, so must museum education become more flexible. So how would the very well-known guided tour format look like, if it was revisited with today's audiences in mind? What if it centered interaction, curiosity and creativity? The Detour Guide programme at Nieuwe Instituut tries to do just that.</p>
                    <p className="paragraph white-text">Bergevin, J. L. (2019). Narratives of Transformation: Reframing and naming the impact of activist museum practice on visitors [Doctoral dissertation, School of Museum Studies].</p>
                    <p className="paragraph white-text">Best, K. (2012). Making museum tours better: Understanding what a guided tour really is and what a tour guide really does. Museum Management and Curatorship, 27(1), 35-52.</p>
                    <p className="paragraph white-text">Burnham, R., & Kai-Kee, E. (2011). Teaching in the art museum: Interpretation as experience. Getty Publications. </p>
                    <p className="paragraph white-text">Falk, J. H., & Dierking, L. D. (2018). Learning from museums. Rowman & Littlefield.</p>
                    <p className="paragraph white-text">Kai-Kee, E., Latina, L., & Sadoyan, L. (2020). Activity-based teaching in the art museum: Movement, embodiment, emotion. Getty Publications.</p>
                    <p className="paragraph white-text">Schep, M. (2019). Guidance for guiding: Professionalization of guides in museums of art and history. [Thesis, fully internal, Universiteit van Amsterdam].</p>
                    <p className="paragraph white-text">Simon, N. (2010). The participatory museum. Museum 2.0.</p>
                    <LetsGetStarted label="Appendix"
                    route='../pages/Appendix'
                    />
                </div>
                
            </div>
            <Stickers sticker3={sticker3}
    sticker4={sticker4}/>
    <div className="card-container">
            <p className="paragraph card-middle paragraph-subtitle white-text card-para background-db2 card-wider">If you would like us to accompany you in the process of setting up your own Detour programme, or any other way to get the Detour spirit in your institution, whether big or small, we are happy to help. Please reach out to us at educatie@nieuweinstituut.nl</p>
            </div>
</div>
<Footer message="Good to know all of these things!"
next="/"
back="../pages/Learning"/>
<PageEnd/>
</div>
</>
)

export default Finish