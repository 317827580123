import React from "react";
import '../css/App.css';
import {useNavigate} from 'react-router-dom';


const LetsGetStarted = ({label,route}) => {

let nav = useNavigate ()

return (
<>
<button onClick ={()=> {nav(route)}}className="get-started-button">
     <p className="button-text">{label}</p></button>  
      </> ) 
}
export default LetsGetStarted;