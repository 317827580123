import React from 'react';
import '../css/App.css';
import GuideIntro from '../Components/GuideIntro';
import Footer from '../Components/Footer';
import PageEnd from '../Components/PageEnd';
import Header from '../Components/Header';
import CustomMuxPlayer from '../Components/MuxPlayer';
import Stickers from '../Components/Stickers';

const sticker1 = [
  require('../images/Awardperson.png')]
 const sticker2 = [
         require('../images/Bas.png')]
 const sticker3 = [
         require('../images/Boosje.png') ]
 const sticker4 = [
         require('../images/DetourGuideGuide.png')]
 const sticker5 = [
         require('../images/Dino.png')]

const Intro = () => (
  <>
        <div className="blob-wrapper">
<div className="blob-container">
<div className="blob magicpattern-9"></div>
<div className="blob-2 magicpattern-8"></div>
</div>
</div>
    <Header />
    <div className="index-home background-blue">
      <div className="body">
        <GuideIntro
          title="#1 A Brief Intro to Detours & Detour Guides"
          introduction="If you are here, you have most likely heard of Detours and/or Detour Guides – but what are they, exactly? And what purpose do they serve? In this section, we will first introduce you to the Nieuwe Instituut. Then, you will get to learn how the Detours came to be, and finally the best part: to get to know some Detour Guides!"
        ></GuideIntro>

{/* <Stickers sticker5={sticker1}
sticker1={sticker3}
/> */}
{/* <Stickers 
       sticker1={sticker1}
sticker2={sticker4} /> */}

        <div className="card-container-pages card-wider">
          <p className="home-subt">
            What is the Nieuwe Instituut
          </p>
          <p className="home-paragraph background-db2">
            Nieuwe Instituut is the Netherlands’ national
            museum for architecture, design and digital
            culture. Located in Rotterdam, it acts as a
            museum as well as a research institute, archive
            and agency. It takes care of the National
            Architecture Archive of the Netherlands. Aside
            from its conservation labour, Nieuwe Instituut
            also serves as an amplifier for cultural
            diffusion to the citizens of Rotterdam, the
            Netherlands and anyone else really, with
            intervocality being one of its main values.
          </p>
          <p className="card-para background-db2">
            *Being intervocal = bringing a multiplicity of
            voices, perspectives and logics together through
            productive dialogues and even frictions.{' '}
          </p>

{/* <Stickers sticker3={sticker3}
sticker5={sticker5}/> */}
        </div>

        <div className="card-container-pages card-wider">
          <p className="home-subt">
            Museum Education & Guided Tours - an overview
          </p>
          <p className="home-paragraph background-db2">
            Museum education has been expanding to include
            more participatory and activity-based practices,
            based on the notion that everyone learns
            differently. To keep this guide short and sweet,
            an overview of this evolution (and where the
            Detour Guides fit into it) can be found in the
            Appendix.
          </p>
        </div>

       <Stickers 
      //  sticker1={sticker1}
      //  sticker2={sticker2}
      //  sticker3={sticker3}
sticker4={sticker4}
sticker5={sticker2}/>

        <div className="index">
          <div className="section">
            <p className="card-quote card-narrow align-c">
              ‘I liked it so much I'm going to do it at home
              too.’ (a Primary School student who joined a
              Detour by Maartje){' '}
            </p>
            <div className="card-wider card-container card-left background-db2">
              <p className="paragraph-title">
                1.1 - The Origins of Detours
              </p>
              <p>
                What if institutions listened to other
                perspectives, instead of deciding what
                information to share with their audience?
                What if visitors could take an (inter)active
                role in their own learning?
              </p>
              <p>
                Nieuwe Instituut is not afraid to question
                itself and experiment with new methods, but
                this was not reflected in the way guided
                tours were given before. And thus began the
                search for a way to make the guided
                experience intervocal*, interactive and fun.
              </p>
              <p>
                Moreover, the Co-Learning department’s
                mission is to encourage visitors to realize
                they are the designers of the future – and
                what better way to discover their creativity
                than with creatives themselves? Inspired by
                other museum’s initiatives, an ever-growing
                team of co-creation enthusiasts began
                imagining a different type of museum
                experience.
              </p>
            </div>
            <div className="card-wider card-container card-right background-db2">
              <p className="paragraph-title">
                1.2 What is a Detour Guide
              </p>
              <p>
                Simply put, a Detour Guide is a creative
                human being who can be found in cultural
                environments both near and far from your
                institution. This could include visual
                artists, designers and architects, but also
                dancers, musicians, performers, spoken word
                poets, researchers, DJs and more!{' '}
              </p>
              <p>
                Detour Guides are known for their open and
                curious nature, as well as their drive to
                interact with people and co-create knowledge
                and creative outputs together. They
                facilitate engaging and personal guided
                tours, in which they look at the museum’s
                programme through the lens of their own
                practice and bring participants* along on
                their journey. Above all: they are mostly
                themselves – and therefore invite others to
                be so as well!
              </p>
              <p>
                They could be employed by your institution
                in whichever way is suitable (for instance,
                our Detour Guides are freelancers), and love
                to be in a group with other Detour Guides
                from different disciplines – more on this
                later.
              </p>
            </div>
          </div>
        </div>
        
        <div className="img-contain background-b">
          <CustomMuxPlayer playbackId="cG5OKT8RwNsuEy9HBC8NRJnYaiWCYZchZ8UY00UI8B02A" />
        </div>

        <Stickers 
       sticker1={sticker1}
       sticker5={sticker2}
      //  sticker3={sticker3}
sticker2={sticker4}

/>

        <div className="index">
          
          <div className="card-container card-wider card-left background-db2">
            <p className="paragraph-title">
              What does a Detour Guide look like?
            </p>
            
            <p>
              Detours can come in all forms and themes and
              can be for adults and children alike! However,
              all Detours have three common elements:
              interaction, creation and character.
            </p>
            <ul>
              <p>
                <span className="bold">Interaction.</span> A
                Detour always involves good questions
                interaction! Detour Guides facilitate
                interesting conversationsexchanges with the
                group; knowledgevalue is shared and created
                together.
              </p>
              <p>
                <span className="bold">Creation.</span> No
                Detour is complete without a creative
                assignment! As creatives themselves, Detour
                Guides are big fans of learning through
                doing and making.
              </p>
              <p>
                <span className="bold">Character.</span>{' '}
                Detour Guides create their tour themselves
                based on their artistic practice, life
                experience and personality. This makes each
                Detour authentic, personal and entirely
                unique.
              </p>
            </ul>
          </div>
          <Stickers sticker3={sticker3}
sticker4={sticker5}/>
          <div className="card-container card-left background-db2">
            <p className="paragraph-title white-text">
              Would you like to experience a Detour?
            </p>
            <p>
              Are you ready to broaden your view on what
              Museum Education can be? We would love to
              welcome you! In case you would like to get a
              virtual taste, here you can find two Detour
              excerpts. One is given by Pia, whose specialty
              is lifestyle, and another by Erik, who works
              with speculative storytelling. We hope this
              will help you get a better idea of what
              Detours can be!
            </p>
            <div>
              
              <div className="detour-contain">
                <div className="detour-vid card-middle">
                  <CustomMuxPlayer playbackId="SpgegAoMHcGNfRZBDnnpPbyEQg02pFtc38402VuCsIJRs" />
                </div>
                
                <div className="detour-vid align-right">
                  <CustomMuxPlayer playbackId="ixF1oRPAJedg9aRF02fiyjL003drVh5jxLkB023kHp6gms" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        message="Sounds interesting, tell me more!"
        next="../pages/Becoming"
        back="/"
      />
      <PageEnd />
      
    </div>
  </>
);
export default Intro;
