import React from "react";
import "../css/App.css";

const Checkbox = ({question, answer}) => (
<>
    <div  className="cb-div">
    <div className="checkbox"></div>
    <div className="check-info">
            <p className="cb-question">{question}</p>
            <p className="cb-answer">{answer}</p>
            </div>
        </div>

</>
)

export default Checkbox;