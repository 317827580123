// import React from "react";
// import { useRef } from "react";
// import { useState } from "react";
// import '../css/Navigation.css';
// import '../css/App.css';
// import {useNavigate} from 'react-router-dom';
// import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

// const NavMobile = () => {

//   const [show, setShow] = useState(false);
//   const toggleHamburger = () => {
//     setShow(!show)
//   }

// return (

// <div>
// <div className="mb-nav-container">
//     <div className="collapsed">
//     <div className="mb-logo"></div>
//     <button onClick={() => setShow(!show)} className="button" onClick={toggleHamburger}></button>
//     </div>
// </div>
//     <div className="expanded">
//   {show ? <div className="navigation-mb">
//         <ul>
//        <Link to="/pages/Intro" onClick={() => setShow(!show)}>#1 Intro</Link>
//         <Link to="/pages/Becoming" onClick={() => setShow(!show)}>#2 Becoming</Link>
//         <Link to="/pages/Finding" onClick={() => setShow(!show)}>#3 Finding</Link>
//         <Link to="/pages/Working" onClick={() => setShow(!show)}>#4 Working</Link>
//         <Link to="/pages/Building" onClick={() => setShow(!show)}>#5 Building</Link>
//         <Link to="/pages/Learning" onClick={() => setShow(!show)}>#6 Learning</Link>
//         <Link to="/pages/Finish" onClick={() => setShow(!show)}>#7 Finish Line</Link>
//       </ul>
//       </div>
//     : null}
// </div>
// </div>

// );

// };

// export default NavMobile;

// NavMobile.jsx
import React, { useState } from "react";
import { Link } from "react-router-dom";
import '../css/Navigation.css';
import PageEnd from "./PageEnd";

const NavMobile = () => {
  const [show, setShow] = useState(false);

  const toggleHamburger = () => {
    setShow(!show);
  };

  const handleLinkClick = () => {
    setShow(false); // Close the navigation menu when a link is clicked
  };

  return (
    
    <div>
      <div className="mb-nav-container">
        <div className="collapsed">
          <div className="mb-logo">
          {/* <a href="/" class="heading">Detour Guide Guide</a> */}
          </div>
          <button className={`button ${show ? 'active' : ''}`} onClick={toggleHamburger}></button>
        </div>
      </div>
      <div className={`expanded ${show ? 'show' : ''}`}>
 
        <div className="navigation-mb">
          <ul>
            <div className="blob-wrapper">
          <div className="blob magicpattern-9"></div>
<div className="blob-2 magicpattern-8"></div>
</div>
          <li><Link to="/" onClick={handleLinkClick}>Home</Link></li>
            <li><Link to="/pages/Intro" onClick={handleLinkClick}>#1 Intro</Link></li>
            <li><Link to="/pages/Becoming" onClick={handleLinkClick}>#2 Becoming</Link></li>
            <li><Link to="/pages/Finding" onClick={handleLinkClick}>#3 Finding</Link></li>
            <li><Link to="/pages/Working" onClick={handleLinkClick}>#4 Working</Link></li>
            <li><Link to="/pages/Building" onClick={handleLinkClick}>#5 Building</Link></li>
            <li><Link to="/pages/Learning" onClick={handleLinkClick}>#6 Learning</Link></li>
            <li><Link to="/pages/Finish" onClick={handleLinkClick}>#7 Finish Line</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavMobile;
