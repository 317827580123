import React from "react";
import '../css/App.css';
import {useNavigate} from 'react-router-dom';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';


const Footer = ({message,next,back}) => {
    let nav = useNavigate ()
return (
<>
<div className="footer-container">
    <p className="footer-text">{message}</p>
    <div className="button-container">
 <button onClick ={()=> {nav(back)}}className="button-bottom">
<p className="backnext-link">Go Back</p>
    </button>
    <button onClick ={()=> {nav(next)}}className="button-bottom">
    <p className="backnext-link">Next</p>
    </button>
   
    </div>
    
</div>
</>
)
};

export default Footer;