import React from "react";
import '../css/App.css';
import Subtitle from '../Components/Subtitle.jsx';
import ParagraphTitle from '../Components/ParagraphTitle.jsx';
import Title from "../Components/Title.jsx";
import Card from "../Components/Card.jsx";
import Footer from "../Components/Footer.jsx";
import ImageCarousel from '../Components/Carousel.jsx';
import Navigation from '../Components/Navigation.jsx'
import {useNavigate} from 'react-router-dom';
import LetsGetStarted from '../Components/LetsStartButton.jsx'
import PageEnd from "../Components/PageEnd.jsx";
import Stickers from '../Components/Stickers';

const sticker1 = [
  require('../images/Duif.png')]
 const sticker2 = [
         require('../images/NieuweInstituut.png')]
 const sticker3 = [
         require('../images/Shahaila.png') ]
 const sticker4 = [
         require('../images/Stoelding.png')]
 const sticker5 = [
         require('../images/Zilvervis.png')]


const carouselImages = [
//   require('../images/1.jpg'),
  require('../images/2.jpg'),
  require('../images/3.jpg'),
//   require('../images/4.jpg'),
 ];


const Home = () => (
<>
<div className="blob-wrapper">
<div className="blob-container">
<div className="blob magicpattern-9"></div>
<div className="blob-2 magicpattern-8"></div>
</div>
</div>
<div className="index-home background-blue">
<div className="body">
        <div className="mb-intro">
        <a href="/" class="heading-mb">Welcome to the Detour Guide Guide</a>
        </div>
<div className="img-contain">
         <ImageCarousel images={carouselImages} />  
</div>
<Stickers sticker1={sticker1}
        sticker2={sticker2}
        sticker5={sticker5}/>
    <div className="card-container card-middle align-c">
    <h2 class="paragraph-title align-c card-middle">What is the Detour Guide Guide?</h2>
    <p className="home-paragraph card-middle card-wider background-db2">Nieuwe Instituut (the Dutch national museum for architecture, design and digital culture) has a slightly unconventional way of hosting guided tours. Detour Guides are creatives of every discipline you can imagine, who create their own tours (called Detours) based on their unique artistic perspective. Detours can therefore be very different, but always involve group interactions and a creative assignment. In this guide, we will share with you all our learnings, tips and tricks, and give you the tools to start such a programme within your own institution.  </p>
    <h2 class="paragraph-title align-c card-middle">Who is this guide for?</h2>
    <p className="home-paragraph card-middle card-wider background-db2">This guide is aimed at professionals in the cultural sector who would like to see a shift within their institution, in order to amplify all the different voices in their community. If you believe in the power of interacting with your audience and the creatives in your environment in a novel way, then this guide is for you! (And if you are just browsing, you are very welcome too!) </p>
    <LetsGetStarted label={"Let's Get Started!"}
        route='../pages/Intro'
/>
    </div>
    <div className="img-contain-mb">
         <ImageCarousel images={carouselImages} />  
</div>
   <div className="home-end-container">
<div className="grid">
<div className="card-container card-wide background-blue2">
        <p className="paragraph-title">What are Detour Guides?</p>
        <p className="intro-text">They are creatives of every discipline you can imagine (graphic design, architecture, dance, poetry, music, and much more!), who create their own guided tours (called Detours) based on their unique artistic perspective. They are characterized by their open and playful nature, as well as their excitement to connect and interact with people.</p>
</div>
<div className="card-container card-wide background-blue2">
        <p className="paragraph-title">Where did this originate?</p>
        <p className="intro-text">Detours started in 2019 in the Nieuwe Instituut (the Dutch national museum for architecture, design and digital culture). They were – and still are! – an exploration on how to take a co-creative approach to guiding in the museum. The programme is continuously growing and evolving.</p>
</div>
<div className="card-container card-wide background-blue2">
        <p className="paragraph-title">Where can I see a little preview of a Detour?</p>
        <p className="intro-text">We recommend quickly looking through section #1 of this guide. This will give you an idea of whether this is something for you!</p>
        <p>If you want a slightly longer audiovisual introduction, click here. </p>
</div>
<div className="card-container card-wide background-blue2">
        <p className="paragraph-title">Why do I need this?</p>
        <p className="intro-text">If you love museums, you have probably experienced a guided tour where you were bored or otherwise underwhelmed at least once and believe that cultural institutions should take into account what their audiences enjoy. This is the way we found to tackle both of these issues; you can either use it as inspiration or as a precise how-to guide.</p>
</div>
<Stickers  sticker3={sticker3}
        sticker4={sticker4}/>
</div>
<LetsGetStarted label={"Let's Get Started!"}
route='../pages/Intro'
/>
</div>

<div className="par-spacer"></div>

</div>
<PageEnd/>
</div>



</>
)

export default Home;