import React from "react";
import '../css/App.css';
import GuideIntro from "../Components/GuideIntro";
import Checkbox from "../Components/Checkbox";
import Footer from "../Components/Footer";
import Card from "../Components/Card";
import Title from "../Components/Title";
import PageEnd from "../Components/PageEnd.jsx";
import Stickers from '../Components/Stickers';

const sticker1 = [
  require('../images/Stoelding.png')]
 const sticker2 = [
         require('../images/Zilvervis.png')]
 const sticker3 = [
         require('../images/Amoebe.png') ]
 const sticker4 = [
         require('../images/Awardperson.png')]
 const sticker5 = [
         require('../images/Bas.png')]


const Working = () => (
<>

<div className="blob-wrapper">
<div className="blob-container">
<div className="blob magicpattern-9"></div>
<div className="blob-2 magicpattern-8"></div>
</div>
</div>

<div className="index background-blue">
    <div className="body">
       
            <GuideIntro title="#4 Working with / caring for your Detour Guides"
                introduction = "Detour Guides and their institution have a symbiotic relationship, based on mutual trust, respect and care. ou as an institution have certain responsibilities towards your Detour Guides. The main pillars which support this relationship are:">
                </GuideIntro>
                <div className="grid">
                <div className="card-container card-wide background-db2">
                            <p className="paragraph-title">Trust</p>
                            <p className="intro-text">Detour Guides thrive on trying out new things. They do not need constant, close supervision. Trust that you chose your Detour Guides carefully; if you take care of them, they can manage the rest themselves.</p>
                            <p className="intro-text">Detour Guides create their own Detours, so there is no grid to conform to. Yes, we do guide them – but ultimately, the form their Detour takes is up to them.  NO MICROMANAGERS ALLOWED! LET CREATIVITY FLOW!!!!!</p>
                            <p className="intro-text">That also means that they do not have to be ‘neutral’ (if that even exists) within their role as a Detour Guide ; we trust them to articulate and justify their reasonings. As long as they maintain a safe space for participants, they have full freedom.</p>
                        </div>
                        <Stickers sticker1={sticker1}
                sticker2={sticker2}
                sticker3={sticker3} />
                    <div className="card-container card-wide background-blue2">
                            <p className="paragraph-title">Transparency</p>
                            <p className="intro-text">Co-creation needs transparency; there are more people involved and clear communication is essential to stay aligned.</p>
                            <p className="intro-text">We are and always have been clear with our Detour Guides – on how much we pay for what and the decision process behind who gets to do which Detour, for instance. Besides this, we have bi-monthly meetings in which the agenda is decided together.</p>
                    </div>
                    <div className="card-container card-wide background-blue2">
                            <p className="paragraph-title">Interaction</p>
                            <p className="intro-text">As we have extensively mentioned before, Detour Guides thrive on the chance to connect with other people. You can (and should) support them in learning with, from and about each other.</p>
                            <p className="intro-text">We organize a monthly Detour 4 Detours session in which the Detour Guides can walk along with another Detour. We also schedule a moment for them to come together whenever a new exhibition opens. In this way, they can discuss their research, insights and ideas after each briefing.</p>
                            <p className="intro-text">We also care about our Detour Guides feeling seen. For example, we send everyone a card on their birthday. Small details like this matter and show your appreciation.</p>
                    </div>
                    <div className="card-container card-wide background-db2">
                            <p className="paragraph-title">Safety</p>
                            <p className="intro-text">You protect your Detour Guides by providing appropriate training on how to react in difficult situations. If their boundaries are overstepped, you are ready to stand with them. This creates a safer experience for everyone. Safety is not only physical; it is also ensuring the emotional wellbeing of both Detour Guides and participants.</p>
                            <p className="intro-text">Detour guides might sometimes find themselves in tricky situations. For instance, what do you do when the kids are super tired and don’t want to listen? What if you are losing the attention of the group? Who can you call on for help, during and after the fact? We have prepared some answers and there is always someone they can reach out to. First rule: Whenever you don’t feel safe, stop the Detour. We will always stand behind your decision.</p>
                            <p className="intro-text">As another example, one of the Detour Guides recently organized a training on pronouns for the entire organisation. Stimulating a welcoming environment for all our colleagues, Detour Guides and everybody in our Detours.</p>
                    </div>
                    {/* <p className="card-quote card-wide align-c">‘Truly a project entirely of our time.’ (a Middle School Teacher who joined an online Detour by Pia and Florian)</p> */}
                    <div className="card-container card-wide background-db2">
                            <p className="paragraph-title">Growth</p>
                            <p className="intro-text">Part of your mission as an institution is to be a nurturing space for your Detour Guides to learn and develop themselves further. Everything you pour into them flows back into your organization and towards your visitors. You should keep a finger on the pulse, so to speak, so you can organize the trainings that will be most impactful in this process.</p>
                            <p className="intro-text">Do you have a Detour guide who is exceptionally good at being a talk show host? Let them host a discussion! Or maybe you have an enthusiastic graphic designer who also gives Detours and can collaborate with you on another project. Let them be ambassadors for their own work and Detour Guides at once, and create space for them to expand within your institution! </p>
                    </div>  
                   
                    <div className="card-container card-wide background-blue2">
                            <p className="paragraph-title">Reliability</p>
                            <p className="intro-text">You commit to providing your Detour Guides with work. This means ensuring your institution’s audience is large enough that you can provide the Detour Guides with enough work to stay connected. They should become an integral part of your way of working. For us, this means each Detour Guide gives at least 3 Detours a month.</p>
                            <p className="intro-text">To ensure this, we offer a weekly free Detour Night where everybody can join a Detour. Good for the world, good for the Detour Guides.</p>
                    </div>
                    <div className="card-container card-wide background-blue2">
                            <p className="paragraph-title">Flexibility</p>
                            <p className="intro-text">When you hire Detour Guides, you should be aware that they have their own artistic practice; giving Detours is an extra activity for them, not the main one. As such, their availability is limited; having an appropriately sized group will allow them to balance each other's schedules out and keep on growing creatively both in and outside of your institution.</p>
                            <p className="intro-text">We keep in touch and keep the conversation open – it’s okay if they are working on the opening of their own exhibition, and we can plan accordingly. If a Guide wants to do an artist in residence in the Philippines on the consequences of plastic waste and how to build a new world with those plastics, they should! It only enriches the Detour Guides’ practice, and they are also very welcome to bring their experience back to us.</p>
                    </div>
                    <div className="card-container card-wide background-db2">
                            <p className="paragraph-title">Fair Pay</p>
                            <p className="intro-text">Preparing and giving Detours is fun, but it is also a lot of work. It is unfortunately a very common practice to expect creatives to work for free, but we don’t do that! A fair compensation is the most fundamental way to show you understand and appreciate the amount of work that goes into creating. Pay a proper rate for the preparation, creation and cooperation. This ensures the quality of the work and the motivation of everybody involved.</p>
                            <p className="intro-text">Every moment that they work for us counts, whether it is for attending a meeting, preapring a Detour, giving their feedback or making a birthday card for another Detour Guide. Furthermore, we provide them with 10 “reading hours”, in which they are free to learn and research about topics of their interest and develop their Detour.</p>
                    </div>
                    
                    </div>
                    {/* <Stickers sticker4={sticker1}
                // sticker5={sticker2}
                sticker3={sticker2} /> */}
                    </div>
                    <Footer message="Now that you have this you are probably wondering: But how do you build a Detour?"
                    next="../pages/Building"
                    back="../pages/Finding"/>
<PageEnd/>
                </div>
       

</>

)

export default Working;