// Navigation.jsx
import React, { useState, useEffect } from 'react';
import '../css/Navigation.css';
import {useNavigate} from 'react-router-dom';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
// import './Navigation-b.css';

let nav = (useNavigate);

const Navigation = () => {
  const [isNavVisible, setNavVisibility] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingUp = prevScrollPos > currentScrollPos;

      setNavVisibility(isScrollingUp || currentScrollPos === 0);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <nav className={`navigation ${isNavVisible ? 'visible' : 'hidden'}`}>
      <ul>
        <Link to="/pages/Intro">#1 Intro</Link>
        <Link to="/pages/Becoming">#2 Becoming</Link>
        <Link to="/pages/Finding">#3 Finding</Link>
        <Link to="/pages/Working">#4 Working</Link>
        <Link to="/pages/Building">#5 Building</Link>
        <Link to="/pages/Learning">#6 Learning</Link>
        <Link to="/pages/Finish">#7 Finish Line</Link>
      </ul>
    </nav>
  );
};

export default Navigation;

