import React from "react";
import "../css/App.css";
import GuideIntro from "../Components/GuideIntro";
import Checkbox from "../Components/Checkbox";
import Footer from "../Components/Footer";
import Navigation from "../Components/Navigation";
import {useNavigate} from 'react-router-dom';
import PageEnd from "../Components/PageEnd.jsx";
import Stickers from "../Components/Stickers.jsx";


const sticker1 = [
    require('../images/Dino.png')]
   const sticker2 = [
           require('../images/Duif.png')]
   const sticker3 = [
           require('../images/Maartje.png') ]
   const sticker4 = [
           require('../images/NieuweInstituut.png')]
   const sticker5 = [
           require('../images/Shahaila.png')]

const Building = () => (
<>
<div className="blob-wrapper">
<div className="blob-container">
<div className="blob magicpattern-9"></div>
<div className="blob-2 magicpattern-8"></div>
</div>
</div>
<div className="index background-blue">
    <div className="body">
    <GuideIntro title="#5 Building a Detour"
    introduction = "Detours are site - (and Guide-) specific experiences, which makes each of them completely unique. Part of the magic of Detours is you can experience the same building or exhibition from several different points of view! But how are Detours built? As we mentioned before, the golden rules of a good Detour are interaction, creation and character. Knowing this, what is the usual structure of a Detour?">
</GuideIntro>
            <div className="card-container">
                
                <p className="home-subt card-middle">The creative part!</p>
                <div className="grid">
              
                <div className="card-container card-wide background-blue2">
                            <p className="paragraph-title">1. Start with an introduction</p>
                            <p className="intro-text">The Detour Guide should tell something about themselves and your institution and explain what a Detour is, so participants know what to expect. Have a first interaction with the group and make it fun. For example, our Detour Guide Wietske often asks participants what their weather forecast for the day is. Sunny with a few clouds? Electric storm? Every answer is a good answer!</p>
                            </div>
                <div className="card-container card-wide background-blue2">
                            <p className="paragraph-title">2. Follow a “red thread"</p>
                            <p className="intro-text">The Detour Guide should tell something about themselves and your institution and explain what a Detour is, so participants know what to expect. Have a first interaction with the group and make it fun. For example, our Detour Guide Wietske often asks participants what their weather forecast for the day is. Sunny with a few clouds? Electric storm? Every answer is a good answer!</p>
                            </div>
                <div className="card-container card-wide background-blue2">
                            <p className="paragraph-title">3. Time for a creative assignment!"</p>
                            <p className="intro-text">It can be anything, as long as it relates to your practice and the exhibition itself. For example: comment on our archival pieces with stories that weren’t told, dance like the internet or design a table for all the non-humans living in the garden so they can solve their issues together.</p>
                            </div>
                <div className="card-container card-wide background-blue2">
                            <p className="paragraph-title">4. Wrap it up!"</p>
                            <p className="intro-text">Depending on what you made and the energy levels of the group, you could end up doing a fashion show, sharing your results in pairs, or choosing volunteers to present to the whole group. Then it is time to say goodbye until you meet again! Detours usually last between 1 and 1.5 hours – which will surely leave some participants wanting more.</p>
                            </div>
                            </div>
                </div>
                <Stickers sticker1={sticker1}
sticker5={sticker4}
sticker3={sticker2}
/>
                <p className="card-quote card-wide align-c">‘Your particular take on mediation (…) is a refreshing take on the traditional educational program. We have experienced at first hand that it can be interactive, fluid and authentic, indeed that it does not have to be neutral’ (a High School teacher who joined a Detour by Pia)</p>

            <div className="card-container background-db2 card-wider card-middle">
                <p className="card-para white-text card-middle">To get a better idea of how different Detours can be, these are some Detours we have experienced and loved:</p>
                <p className="paragraph white-text">During the exhibition Malware: Symptoms of Viral Infection (2019) our Detour Guide Bas (who is a dancer and philosopher), asked participants to move around the space. At one point, he would tap them on the shoulder – from then on, they had to move like they were infected by a virus.</p>
                <p className="paragraph white-text">At the exhibition Workwear (2023) Detour Guide and ‘creative girlie’ Pia made us design clothes for all the emotional labour that often goes unnoticed.</p>
                <p className="paragraph white-text">For the exhibition Archives at Risk (2023), Detour Guide and sculptor Laura asked participants to add their own designs to archival pieces, to preserve their stories for centuries to come.</p>
                <p className="paragraph white-text">On an adventure through the New Garden during the Detour through the Zoöp (2022) with Detour Guide and artist Maartje, we discovered the mysterious and sometimes even criminal world of Insects.</p>
            </div>
      
     </div>
     <Footer message="Sounds good, anything else I should know?"
     next="../pages/Learning"
     back="../pages/Working"/>
<PageEnd/>
     </div>
        
</>
)

export default Building;