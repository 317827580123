import React from "react";
import "../css/App.css";
import GuideIntro from "../Components/GuideIntro";
import Checkbox from "../Components/Checkbox";
import Footer from "../Components/Footer";
import Navigation from "../Components/Navigation";
import {useNavigate} from 'react-router-dom';
import PageEnd from "../Components/PageEnd.jsx";
import Stickers from '../Components/Stickers';

const sticker1 = [
  require('../images/NieuweInstituut.png')]
 const sticker2 = [
         require('../images/Shahaila.png')]
 const sticker3 = [
         require('../images/Stoelding.png') ]
 const sticker4 = [
         require('../images/Zilvervis.png')]
 const sticker5 = [
         require('../images/Amoebe.png')]



const Becoming = () => (
<>

<div className="blob magicpattern-9"></div>
<div className="blob-2 magicpattern-8"></div>

<div className="index background-blue">
    <div className="body "><GuideIntro title="#2 Becoming a Welcoming Environment"
    introduction = "Now that you have met some of the Detour Guides of Nieuwe Instituut, you might be wondering how you could set up such a programme yourself, or at least bring some of its essence to your institution. Before you do that, we have some questions for you to reflect on.">
</GuideIntro>
<Stickers 
    sticker1={sticker4}
    sticker4={sticker5}
    sticker5={sticker3}
/>
        <div className="section">
            <div className="cb-container">
                <Checkbox question = "Are you convinced that something needs to change in your institution?"
                            answer = "This will not necessarily be an easy process, and some people within your organization might be sceptical in the beginning. You might have to present this as a ‘pilot’ project - many will be convinced after seeing it in motion."/>
            <Checkbox question = "Are you open to changing your own role?"
                            answer = "Instead of developing the programmes yourself (writing scripts for tour guides to execute, for instance) you will become a cheerleader, booster and protector for your Detour Guides."/>
                <Checkbox question = "Are you willing to let go of neutrality and open yourself to critical discussions? Are you open to be challenged?"
                            answer = "Traditionally, many cultural organizations try to remain as neutral as possible in their positioning. However, Detour Guides have no such intentions; their job involves sharing their opinion, and they might not always agree with you."/>
                <Checkbox question = "Are you ready to renew your worldview? To “never look at an orange the same way again”, as one of our participants once said?"
                            answer = "Enough said – Detour Guides will open you up to a whole new range of perspectives. Be ready to get out of the box!"/>
                <Checkbox question = "Finally, are you committed to investing time, money and brains into establishing this programme and supporting it in its development?"
                            answer = "Of course, becoming a welcoming environment for Detour Guides will demand resources from your organization, both human and material. Although it is 100% a worthy investment in our experience, it is something to be aware of!"/>
            </div>
        </div>
        <Stickers 
sticker1={sticker1}
sticker2={sticker2}
sticker3={sticker3}/>
        <div className="card-container no-border">
        <p className="card-para card-middle white">If you said yes to all of the above, we are happy to announce that you are ready to start building your programme!</p>
        </div>
    </div>
    <Footer message ="But where can you find your Detour Guides?"
    next="../pages/Finding"
    back="../pages/Intro"/>
    <PageEnd/>
</div>
</>
)

export default Becoming;