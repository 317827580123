import React from "react";
import "../css/App.css";
import GuideIntro from "../Components/GuideIntro";
import Footer from "../Components/Footer";
import Card from "../Components/Card";
import Title from "../Components/Title";
import PageEnd from "../Components/PageEnd";

const Appendix = () => (
<>
<div className="blob-wrapper">
<div className="blob-container">
<div className="blob magicpattern-9"></div>
<div className="blob-2 magicpattern-8"></div>
</div>
</div>
    <div className="index background-blue">
                    <div className="body">
                    <GuideIntro title="Appendix"
>                    </GuideIntro>
            <div className="section-box">
                <div className="card-container no-border grid">
                </div>
            </div> 
            <div className="section-box">
                <div className="card-container background-blue2">
                    <p className="paragraph white-text">Bergevin, J. L. (2019). Narratives of Transformation: Reframing and naming the impact of activist museum practice on visitors [Doctoral dissertation, School of Museum Studies].</p>
                    <p className="paragraph white-text">Best, K. (2012). Making museum tours better: Understanding what a guided tour really is and what a tour guide really does. Museum Management and Curatorship, 27(1), 35-52.</p>
                    <p className="paragraph white-text">Burnham, R., & Kai-Kee, E. (2011). Teaching in the art museum: Interpretation as experience. Getty Publications. </p>
                    <p className="paragraph white-text">Falk, J. H., & Dierking, L. D. (2018). Learning from museums. Rowman & Littlefield.</p>
                    <p className="paragraph white-text">Kai-Kee, E., Latina, L., & Sadoyan, L. (2020). Activity-based teaching in the art museum: Movement, embodiment, emotion. Getty Publications.</p>
                    <p className="paragraph white-text">Schep, M. (2019). Guidance for guiding: Professionalization of guides in museums of art and history. [Thesis, fully internal, Universiteit van Amsterdam].</p>
                    <p className="paragraph white-text">Simon, N. (2010). The participatory museum. Museum 2.0.</p>
                </div>
            </div>
</div>
<Footer message="Good to know all of these things!"
next="/"
back="../pages/Finish"/>
<PageEnd/>
</div>
</>
)

export default Appendix;