import React from "react";
import "../css/App.css";
import GuideIntro from "../Components/GuideIntro.jsx";
import Checkbox from "../Components/Checkbox.jsx";
import Footer from "../Components/Footer.jsx";
import Card from "../Components/Card.jsx";
import Title from "../Components/Title.jsx";
import Checkboxb from "../Components/Checkboxb.jsx";
import PageEnd from "../Components/PageEnd.jsx";
import Stickers from "../Components/Stickers.jsx";
const sticker1 = [
    require('../images/Amoebe.png')]
   const sticker2 = [
           require('../images/Awardperson.png')]
   const sticker3 = [
           require('../images/Bas.png') ]
   const sticker4 = [
           require('../images/Boosje.png')]
   const sticker5 = [
           require('../images/DetourGuideGuide.png')]
  


const Learning = () => (
<>
<div className="blob-wrapper">
<div className="blob-container">
<div className="blob magicpattern-9"></div>
<div className="blob-2 magicpattern-8"></div>
</div>
</div>

<div className="index background-blue">
    <div className="body">
       
        <GuideIntro title="#6 Learning(s) from (our) experience"
        introduction="Since we started doing Detours in 2019, the programme has seen many iterations. From finding the first batch of Detour Guides to shifting to DIY-at-home Detours during the COVID-19 pandemic, we have had to get creative! Now, the Detour Guides have grown into a much broader role within the Nieuwe Instituut, taking part in many other projects. In this section, we would like to transmit to you some of our learnings in setting up the programme and seeing its evolution – so you can do the same!"
        ></GuideIntro>
            <div className="card-container card-middle card-wider">
                <p className="home-subt card-middle align-c">Challenges</p>
                <div className="grid">
                <p className="card-para background-blue2">There is no innovation without trial and error – and no creativity without some resistance. Some of the challenges we found (and could foresee you encountering too) are as follows:</p>
                <p className="card-para background-blue2"><span className="bold">Scepticism from within your institution.</span> Change will often ruffle some feathers, especially in a domain as ingrained into cultural institutions as guided tours. You might be met with colleagues’ doubts in the beginning. However, most of them will be convinced once they get to witness (and experience) the benefits of having Detour Guides around. Maybe you will even see these doubtful colleagues dancing around your institution.</p>
                <p className="card-para background-blue2"><span className="bold">Hesitation from Detour participants.</span> Detour guides are a new phenomenon to many, so don’t be surprised if people are hesitant to participate sometimes. It helps to start the Detour small and always tailor the story towards the participants Let them take partial ownership of the Detour by really seeking out the interaction.</p>
                <p className="card-para background-blue2"><span className="bold">Creativity overload.</span> (Is there such a thing?) Detour Guides have so much initiative and so many ideas that we wish we could realize them all. However, being part of a large institution comes with its own share of rules and limitations. So how can we prevent our Detour Guides from being disappointed? By being very transparent about what is possible, showing them that we are trying our best to make things happen, and finally by celebrating the moments when their ideas are brought to life. Overall, this can require some expectation management and assertive decision-making from your side.</p>
            </div>
            </div>
            <Stickers sticker1={sticker1}
            sticker2={sticker2}
            sticker3={sticker3} />
            <div className="card-container card-middle card-wider ">
                <p className="home-subt card-middle align-c">Victories & Highlights</p>
                <div className="grid">
                <p className="card-para background-blue2">We have already discussed the challenges you might face when establishing a programme such as this one. There is no reward without effort – but also no effort without its reward! Working with Detour Guides brings many bright moments to your institution. Some of our favourites are:</p>
                <p className="card-para background-blue2"><span className="bold">Beating museum fatigue and engaging (new) audiences.</span> Change will often ruffle some feathers, especially in a domain as ingrained into cultural institutions as guided tours. You might be met with colleagues’ doubts in the beginning. However, most of them will be convinced once they get to witness (and experience) the benefits of having Detour Guides around. Maybe you will even see these doubtful colleagues dancing around your institution.</p>
                <p className="card-para background-blue2"><span className="bold">Seeing your Detour Guides grow</span> Not only do Detour Guides get better and better at giving Detours, but in many cases, they learn a lot about their own artistic practice in the process. As a cultural institution, it is very rewarding to nurture your community in such a tangible way.</p>
                <p className="card-para background-blue2"><span className="bold">Channelling the Detour spirit.</span> In our experience, so much of our institution has become more creative through having our Detour Guides around. Events like FamilieFest (a full day of activities for families, organized during each school holiday) now have an amazing atmosphere and great storytelling thanks to them.</p>
            </div>
            </div>
            <div className="section-box par-spacer card-middle">
                <div className="text-container">
                    <p className="paragraph white-text">And there are many more! We could rave about our Detour Guides for a really long time, but we will let you move on for now. Nevertheless, be sure you are always welcome to come visit an original Detour at the Nieuwe Instituut.</p>
                </div>
            </div>
        </div>
        <Footer message="Good to know all of these things!"
        next="../pages/Finish"
        back="../pages/Building"/>
<PageEnd/>
        </div>

</>
)

export default Learning;